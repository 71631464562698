<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
     <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.push('/ReservationMedicalPackage') "
    />
    <vx-card title="Reservation Medical Package Details">
      <div class="vx-row">

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Reference Number") }}</label>
          <vs-input
            disabled
            v-model="Model.ReferenceNumber"
            class="w-full"
            name="Reference Number"
            :data-vv-as="$t('Reference Number')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Reference Number')"
            >{{ errors.first("Reference Number") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Package Name") }}</label>
          <vs-input
            disabled
            v-if="Model && Model.MedicalPackage"
            v-model="Model.MedicalPackage.NameEN"
            class="w-full"
            name="MedicalPackage"
            :data-vv-as="$t('MedicalPackage')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('MedicalPackage')"
            >{{ errors.first("MedicalPackage") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Hospital") }}</label>
          <vs-input
            disabled
            v-if="
              Model && Model.MedicalPackage && Model.MedicalPackage.Hospital
            "
            v-model="Model.MedicalPackage.Hospital.NameEN"
            class="w-full"
            name="Hospital"
            :data-vv-as="$t('Hospital')"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("Medocal Package Category")
          }}</label>
          <vs-input
            disabled
            v-if="
              Model &&
              Model.MedicalPackage &&
              Model.MedicalPackage.MedicalPackageCategory
            "
            v-model="Model.MedicalPackage.MedicalPackageCategory.NameEN"
            class="w-full"
            name="MedicalPackageCategory"
            :data-vv-as="$t('MedicalPackageCategory')"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Destination") }}</label>
          <vs-input
            disabled
            v-if="
              Model &&
              Model.MedicalPackage &&
              Model.MedicalPackage.Hospital.CountryID>0
            "
            v-model="Model.MedicalPackage.Hospital.Country.NameEN"
            class="w-full"
            name="Destination"
            :data-vv-as="$t('Destination')"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Name") }}</label>
          <vs-input
            v-if="Model && Model.Patient"
            disabled
            v-model="Model.Patient.NameEN"
            class="w-full"
            name="Patient Name"
            :data-vv-as="$t('Patient Name')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Patient Name')"
            >{{ errors.first("Patient Name") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Email") }}</label>
          <vs-input
            disabled
            v-if="Model && Model.Patient"
            v-model="Model.Patient.Email"
            class="w-full"
            :data-vv-as="$t('Patient Email')"
            name="Patient Email"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Patient Email')"
            >{{ errors.first("Patient Email") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Phone") }}</label>
          <vs-input
            disabled
            v-if="Model && Model.Patient"
            v-model="Model.Patient.Phone"
            class="w-full"
            name="Patient Phone"
          />
          <span
            class="text-danger md:w-1/3 text-sm"
            v-show="errors.has('Patient Phone')"
            >{{ errors.first("Patient Phone") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("Prefered Date From")
          }}</label>
          <datepicker
            disabled
            v-model="Model.PreferrdDateFrom"
            class="vx-col w-full"
            name="Date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Prefered Date To") }}</label>
          <datepicker
            disabled
            v-model="Model.PreferrdDateTo"
            class="vx-col w-full"
            name="Date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("Package Start Date")
          }}</label>
          <datepicker
            v-model="Model.PackageStartDate"
              :disabled="!Editable"
            class="vx-col w-full"
            name="Date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
              :disabled="!Editable"
            v-model="Model.StatusID"
            :options="nextStatus"
            :reduce="(ID) => ID.ID"

          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Payment Type") }}</label>
          <v-select
            label="Name"
              :disabled="!Editable"
            class="w-full"
            v-model="Model.PaymentTypeID"
            :options="PaymentTypes"
            :reduce="(ID) => ID.ID"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Payment Type')"
            >{{ errors.first("Payment Type") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            :disabled="!Editable"
            v-model="Model.PaymentStatusID"
            :options="PaymentStatus"
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Price USD") }}</label>
          <vs-input
            disabled
            v-model="Model.Price"
            class="w-full"
            name="Price USD"
            :data-vv-as="$t('Price')"
          />
          <span class="text-danger text-sm" v-show="errors.has('Price')">{{
            errors.first("Price")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Net Markup USD") }}</label>
          <vs-input
            disabled
            v-model="Model.MarkupFees"
            class="w-full"
            name="MarkupFees"
            :data-vv-as="$t('MarkupFees')"
          />
          <span class="text-danger text-sm" v-show="errors.has('MarkupFees')">{{
            errors.first("MarkupFees")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("VAT USD") }}</label>
          <vs-input
            disabled
            v-model="Model.VAT"
            class="w-full"
            name="VAT"
            :data-vv-as="$t('VAT')"
          />
          <span class="text-danger text-sm" v-show="errors.has('VAT')">{{
            errors.first("VAT")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Selling Price USD") }}</label>
          <vs-input
            disabled
            v-model="Model.PriceInUSD"
            class="w-full"
            name="PriceInUSD"
            :data-vv-as="$t('PriceInUSD')"
          />
          <span class="text-danger text-sm" v-show="errors.has('PriceInUSD')">{{
            errors.first("PriceInUSD")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("N.Of Patients") }}</label>
          <vs-input
            disabled
            v-model="Model.NumberOfBeneficiary"
            class="w-full"
            name="NumberOfBeneficiary"
            :data-vv-as="$t('NumberOfBeneficiary')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('NumberOfBeneficiary')"
            >{{ errors.first("NumberOfBeneficiary") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("N.Of companions") }}</label>
          <vs-input
            disabled
            v-model="Model.NumberOfCompanions"
            class="w-full"
            name="NumberOfCompany"
            :data-vv-as="$t('NumberOfCompany')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('NumberOfCompany')"
            >{{ errors.first("NumberOfCompany") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("DurationByDay") }}</label>
          <vs-input
            disabled
            v-model="Model.DurationByDay"
            class="w-full"
            name="DurationByDay"
            :data-vv-as="$t('DurationByDay')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('DurationByDay')"
            >{{ errors.first("DurationByDay") }}</span
          >
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="lg:w-1/2 m-5" id="data-list-list-view">
          <vx-card title="Comments">
            <div class="vx-row">
              <vs-input
                :label="$t('Comment')"
                v-model="Comment"
                class="ml-5 mr-5 lg:w-1/2"
                name="Comment"
              />
              <vs-button
                type="border"
                style="height: 3rem !important"
                class="m-6 lg:w-1/4"
                @click="addComment"
                :disabled="Comment == ''"
                >{{ $t("add") }}</vs-button
              >
            </div>
            <vs-table
              ref="table2"
              pagination
              :max-items="5"
              :data="Model.ReservedMedicalPackageComments"
            >
              <template slot="thead">
                <vs-th>{{ $t("Comment") }} </vs-th>
                <vs-th> {{ $t("Person") }}</vs-th>
                <vs-th> {{ $t("Date") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="td" :key="indexr" v-for="(td, indexr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.Comment }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.User.FullName }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.CreatedDate | formatDate() }}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
        <div class="lg:w-2/5 m-5" id="data-list-list-view">
          <vx-card title="Logs">
            <vs-table
              ref="table2"
              pagination
              :max-items="5"
              :data="Model.Patientlogs"
            >
              <template slot="thead">
                <vs-th> {{ $t("Date") }}</vs-th>
                <vs-th> {{ $t("Details") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="td" :key="indexr" v-for="(td, indexr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.CreatedDate | formatDate() }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium ">
                        {{ td.Details }}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>

      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="ml-4 mt-2"
              @click="save_changes"
              :disabled="!isFormValid"

            >
              {{ $t("Save") }}
            </vs-button>
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="danger"
              @click="Back()"
            >
              {{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleReservationMedicalPackage from "@/store/reservation/ReservationMedicalPackage/moduleReservationMedicalPackage.js";
import vSelect from "vue-select";

// import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import modulePaymentType from "@/store/settings/paymentType/modulePaymentType.js";

export default {
  components: {
    vSelect,
    Datepicker,
  },

  data() {
    return {
      paymentStatus: [],
      Model: {
        ReservedMedicalPackageComments: [],
        Patientlogs: [],
      },
      Comment: "",
      // uploadUrl: domain + "API/Course/UploadFile",
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // baseURL: domain,
    };
  },
  watch:{
     StatusID() {
       var vm=this;
       var status = this.status.filter((obj)=>obj.ID==vm.Model.StatusID);
       var Payments=status[0].RelatedPaymentStatus;
         var currentPaymentStatus=this.$store.state.PaymentStatusList.PaymentStatus.filter((obj)=>obj.ID==vm.Model.MainPaymentStatusID);

      this.paymentStatus = this.$store.state.PaymentStatusList.PaymentStatus.filter((obj)=>Payments.includes(obj.ID)
      && obj.OrderPaymentBy >= currentPaymentStatus[0].OrderPaymentBy);
    },
  },
  computed: {
    PaymentStatus()
    {
          return this.paymentStatus;
    },
    StatusID() {
      return  this.Model.StatusID;
    },
    MainStatusProcess()
    {
       var s = this.Model.MainStatusID;
        var status = this.status.filter((obj)=>obj.ID==s);
        if(status!=null&&status!=undefined && status.length>0)
            return status[0].OrderProcessBy;
        else [];    
    },
    Editable()
    {
      return  this.Model.MainStatusID!=5&& this.Model.MainStatusID!=3;
    },
    isFormValid() {

       var vm=this;
      var index = this.PaymentStatus.findIndex((obj)=> obj.ID==vm.Model.PaymentStatusID )
      return !this.errors.any() && this.Model.StatusID != null && index>=0;
    },
    status() {
      return   this.$store.state.StatusList.status;
    },
    nextStatus() {
      debugger
      var vm=this;
      return   this.$store.state.StatusList.status.filter((obj)=>obj.OrderProcessBy >= vm.MainStatusProcess || !obj.OrderProcessBy );
    },
    PaymentTypes() {
      return this.$store.state.PaymentTypeList.PaymentTypes;
    },
  },
  methods: {
    addComment() {
      const obj = {
        ID: this.ID,
        Comment: this.Comment,
        UserID: this.$store.state.AppActiveUser.uid,
        ReservedMedicalPackageID: this.$route.params.ID,
      };

      this.$store
        .dispatch(
          "ReservationMedicalPackageList/AddReservationMedicalPackageComment",
          obj
        )
        .then((res) => {
          debugger;

          this.Model.ReservedMedicalPackageComments.push(res.data.Data);
          this.Comment = "";
        })
        .catch((err) => {
          console.error(err);
          window.showError(err.response.data.message);
        });
    },
    Back() {
      //a7ot el parameter
      this.$router.go(-1);
    },
    save_changes() {
      this.$store
        .dispatch(
          "ReservationMedicalPackageList/UpdateReservedMedicalPackage",
          this.Model
        )
        .then(() => {
          window.showAddSuccess();
          this.Back();

          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            window.showAddFailed(err.response.data.message);
          } else {
            window.showAddFailed("");
          }
          //console.error(err);
          this.$vs.loading.close();
        });
    },
    // successUpload(event) {
    //   this.Model.ProfileImagePath = event.target.responseText;
    //   window.showAddSuccess();
    // },
  },
  created() {
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");
    if (!moduleReservationMedicalPackage.isRegistered) {
      this.$store.registerModule(
        "ReservationMedicalPackageList",
        moduleReservationMedicalPackage
      );
      moduleReservationMedicalPackage.isRegistered = true;
    }
    if (!modulePaymentType.isRegistered) {
      this.$store.registerModule("PaymentTypeList", modulePaymentType);
      modulePaymentType.isRegistered = true;
    }

    this.$store.dispatch("PaymentTypeList/GetAllPaymentTypes");
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");

    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();
      this.$store
        .dispatch("ReservationMedicalPackageList/GetReservedMedicalPackage", ID)
        .then((res) => {
          debugger

          this.Model = res.data.Data;
            this.$set(this.Model,'MainPaymentStatusID',this.Model.PaymentStatusID);
          this.$set(this.Model,'MainStatusID',this.Model.StatusID)
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
        });
    }
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
