/*=========================================================================================
  File Name: moduleReservationMedicalPackageMutations.js
  Description: ReservationMedicalPackage Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ReservationMedicalPackages.unshift(item)
  },
  SET_ReservationMedicalPackage(state, ReservationMedicalPackages) {
    state.ReservationMedicalPackages = ReservationMedicalPackages
  },
  UPDATE_ReservationMedicalPackage(state, ReservationMedicalPackage) {
    const ReservationMedicalPackageIndex = state.ReservationMedicalPackages.findIndex((p) => p.ID == ReservationMedicalPackage.ID)
    Object.assign(state.ReservationMedicalPackages[ReservationMedicalPackageIndex], ReservationMedicalPackage)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.ReservationMedicalPackages.findIndex((p) => p.ID == itemId)
    state.ReservationMedicalPackages.splice(ItemIndex, 1)
},
}
